
import { Container, Row, Col } from 'react-bootstrap';
import './PrimaryActions.css';

function PrimaryActions(props) {
    return (
        // <Container>
            <Row className="justify-content-center px-4">
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="tel:+48 604 386 499">
                        <div className="img-fluid">
                            <img src="./mobile1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Mobile</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="tel:+48 518 659 974">
                        <div>
                        <img src="./office1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Office</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="mailto:agnieszka@serwisaqua.pl">
                        <div>
                        <img src="./email1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Email</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x4716853a46eb9691:0xa50470ac2a5f9e96?source=g.page.share">
                        <div >
                        <img src="./map1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Map</div> */}
                        </div>
                    </a>
                </Col>
                <Col className="col-md-auto p-0">
                    <a className="nounderline link-secondary" href="http://www.serwisaqua.pl">
                        <div >
                        <img src="./website1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="40" width="auto"></img>
                            {/* <div className="ActionName">Website</div> */}
                        </div>
                    </a>
                </Col>
            </Row>
        // </Container>
    )
}

export default PrimaryActions;