
import "./CardHeader.css"
import {Container, Row, Col } from 'react-bootstrap'

function CardHeader() {

    return (
        <header className="header">
            <img style={{maxWidth: "160px"}} src="logo.png"  alt="Serwis Aqua | Profesjonalna pralnia"></img>
        </header>
        
    )

}

export default CardHeader;